$blue-secondary: #03a6f7;
$blue-light: #83d6ff;
$blue-secondary-variant: #cbeeff;
$black-neutral: #081945;
$orange-primary: #ff5b02;
$gray-light-neutral: #989eb2;
$white: white;
$white-secondary: #effaff;
$white-primary: #fff1ea;
$white-neutral: #E1E2E6;
$green-success: #00975F;
$green-light: #00975f3b;
$green-lighter: #a3cfbf;
$gradient: linear-gradient(90deg, rgba(239, 250, 255, 1) 35%, rgba(255, 241, 234, 1) 100%);
$error: #B00020;
